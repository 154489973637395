exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-5-1-reshenia-za-silni-ochi-js": () => import("./../../../src/pages/articles/5-1-reshenia-za-silni-ochi.js" /* webpackChunkName: "component---src-pages-articles-5-1-reshenia-za-silni-ochi-js" */),
  "component---src-pages-articles-cherniat-drob-ne-boli-js": () => import("./../../../src/pages/articles/cherniat-drob-ne-boli.js" /* webpackChunkName: "component---src-pages-articles-cherniat-drob-ne-boli-js" */),
  "component---src-pages-articles-efekta-na-siniata-svetlina-js": () => import("./../../../src/pages/articles/efekta-na-siniata-svetlina.js" /* webpackChunkName: "component---src-pages-articles-efekta-na-siniata-svetlina-js" */),
  "component---src-pages-articles-inovativen-podhod-ukrotiava-jluchkata-js": () => import("./../../../src/pages/articles/inovativen-podhod-ukrotiava-jluchkata.js" /* webpackChunkName: "component---src-pages-articles-inovativen-podhod-ukrotiava-jluchkata-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-articles-kak-da-namalim-riska-js": () => import("./../../../src/pages/articles/kak-da-namalim-riska.js" /* webpackChunkName: "component---src-pages-articles-kak-da-namalim-riska-js" */),
  "component---src-pages-articles-kak-da-se-spravim-s-psoriazisa-js": () => import("./../../../src/pages/articles/kak-da-se-spravim-s-psoriazisa.js" /* webpackChunkName: "component---src-pages-articles-kak-da-se-spravim-s-psoriazisa-js" */),
  "component---src-pages-articles-kak-da-si-spasim-zrenieto-js": () => import("./../../../src/pages/articles/kak-da-si-spasim-zrenieto.js" /* webpackChunkName: "component---src-pages-articles-kak-da-si-spasim-zrenieto-js" */),
  "component---src-pages-articles-kak-da-vijdame-po-dobre-js": () => import("./../../../src/pages/articles/kak-da-vijdame-po-dobre.js" /* webpackChunkName: "component---src-pages-articles-kak-da-vijdame-po-dobre-js" */),
  "component---src-pages-articles-kak-se-pravi-js": () => import("./../../../src/pages/articles/kak-se-pravi.js" /* webpackChunkName: "component---src-pages-articles-kak-se-pravi-js" */),
  "component---src-pages-articles-kruti-merki-js": () => import("./../../../src/pages/articles/kruti-merki.js" /* webpackChunkName: "component---src-pages-articles-kruti-merki-js" */),
  "component---src-pages-articles-lutein-noshtno-vijdane-js": () => import("./../../../src/pages/articles/lutein-noshtno-vijdane.js" /* webpackChunkName: "component---src-pages-articles-lutein-noshtno-vijdane-js" */),
  "component---src-pages-articles-prevantivno-ochno-zdrave-js": () => import("./../../../src/pages/articles/prevantivno-ochno-zdrave.js" /* webpackChunkName: "component---src-pages-articles-prevantivno-ochno-zdrave-js" */),
  "component---src-pages-articles-purva-pomosht-js": () => import("./../../../src/pages/articles/purva-pomosht.js" /* webpackChunkName: "component---src-pages-articles-purva-pomosht-js" */),
  "component---src-pages-articles-reshenie-za-uvreden-cheren-drob-js": () => import("./../../../src/pages/articles/reshenie-za-uvreden-cheren-drob.js" /* webpackChunkName: "component---src-pages-articles-reshenie-za-uvreden-cheren-drob-js" */),
  "component---src-pages-articles-restartirat-zrenieto-js": () => import("./../../../src/pages/articles/restartirat-zrenieto.js" /* webpackChunkName: "component---src-pages-articles-restartirat-zrenieto-js" */),
  "component---src-pages-articles-sarsaparilata-i-psoriazisa-js": () => import("./../../../src/pages/articles/sarsaparilata-i-psoriazisa.js" /* webpackChunkName: "component---src-pages-articles-sarsaparilata-i-psoriazisa-js" */),
  "component---src-pages-articles-silimarin-silen-antioksidant-i-hepatoprotektor-js": () => import("./../../../src/pages/articles/silimarin-silen-antioksidant-i-hepatoprotektor.js" /* webpackChunkName: "component---src-pages-articles-silimarin-silen-antioksidant-i-hepatoprotektor-js" */),
  "component---src-pages-articles-specialistite-kak-da-vijdame-po-dobre-js": () => import("./../../../src/pages/articles/specialistite-kak-da-vijdame-po-dobre.js" /* webpackChunkName: "component---src-pages-articles-specialistite-kak-da-vijdame-po-dobre-js" */),
  "component---src-pages-articles-toksinite-opasen-vrag-js": () => import("./../../../src/pages/articles/toksinite-opasen-vrag.js" /* webpackChunkName: "component---src-pages-articles-toksinite-opasen-vrag-js" */),
  "component---src-pages-articles-ustroistvo-na-stavi-js": () => import("./../../../src/pages/articles/ustroistvo-na-stavi.js" /* webpackChunkName: "component---src-pages-articles-ustroistvo-na-stavi-js" */),
  "component---src-pages-articles-virusite-ni-atakuvat-prez-ochite-js": () => import("./../../../src/pages/articles/virusite-ni-atakuvat-prez-ochite.js" /* webpackChunkName: "component---src-pages-articles-virusite-ni-atakuvat-prez-ochite-js" */),
  "component---src-pages-articles-zasilvat-organ-koito-ni-pazi-js": () => import("./../../../src/pages/articles/zasilvat-organ-koito-ni-pazi.js" /* webpackChunkName: "component---src-pages-articles-zasilvat-organ-koito-ni-pazi-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-hepatofelin-js": () => import("./../../../src/pages/en/hepatofelin.js" /* webpackChunkName: "component---src-pages-en-hepatofelin-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-laksiprokt-js": () => import("./../../../src/pages/en/laksiprokt.js" /* webpackChunkName: "component---src-pages-en-laksiprokt-js" */),
  "component---src-pages-en-liporedukt-js": () => import("./../../../src/pages/en/liporedukt.js" /* webpackChunkName: "component---src-pages-en-liporedukt-js" */),
  "component---src-pages-en-lutein-complex-js": () => import("./../../../src/pages/en/lutein-complex.js" /* webpackChunkName: "component---src-pages-en-lutein-complex-js" */),
  "component---src-pages-en-menopauzal-js": () => import("./../../../src/pages/en/menopauzal.js" /* webpackChunkName: "component---src-pages-en-menopauzal-js" */),
  "component---src-pages-en-miomal-js": () => import("./../../../src/pages/en/miomal.js" /* webpackChunkName: "component---src-pages-en-miomal-js" */),
  "component---src-pages-en-nevroherb-js": () => import("./../../../src/pages/en/nevroherb.js" /* webpackChunkName: "component---src-pages-en-nevroherb-js" */),
  "component---src-pages-en-pielonefral-js": () => import("./../../../src/pages/en/pielonefral.js" /* webpackChunkName: "component---src-pages-en-pielonefral-js" */),
  "component---src-pages-en-privacy-js": () => import("./../../../src/pages/en/privacy.js" /* webpackChunkName: "component---src-pages-en-privacy-js" */),
  "component---src-pages-en-prostatizal-js": () => import("./../../../src/pages/en/prostatizal.js" /* webpackChunkName: "component---src-pages-en-prostatizal-js" */),
  "component---src-pages-en-prostatizal-plus-js": () => import("./../../../src/pages/en/prostatizal-plus.js" /* webpackChunkName: "component---src-pages-en-prostatizal-plus-js" */),
  "component---src-pages-en-psoriazal-js": () => import("./../../../src/pages/en/psoriazal.js" /* webpackChunkName: "component---src-pages-en-psoriazal-js" */),
  "component---src-pages-en-psoriazal-plus-js": () => import("./../../../src/pages/en/psoriazal-plus.js" /* webpackChunkName: "component---src-pages-en-psoriazal-plus-js" */),
  "component---src-pages-en-site-map-js": () => import("./../../../src/pages/en/site-map.js" /* webpackChunkName: "component---src-pages-en-site-map-js" */),
  "component---src-pages-en-stavien-js": () => import("./../../../src/pages/en/stavien.js" /* webpackChunkName: "component---src-pages-en-stavien-js" */),
  "component---src-pages-en-technology-js": () => import("./../../../src/pages/en/technology.js" /* webpackChunkName: "component---src-pages-en-technology-js" */),
  "component---src-pages-en-veniprotekt-js": () => import("./../../../src/pages/en/veniprotekt.js" /* webpackChunkName: "component---src-pages-en-veniprotekt-js" */),
  "component---src-pages-hepatofelin-js": () => import("./../../../src/pages/hepatofelin.js" /* webpackChunkName: "component---src-pages-hepatofelin-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laksiprokt-js": () => import("./../../../src/pages/laksiprokt.js" /* webpackChunkName: "component---src-pages-laksiprokt-js" */),
  "component---src-pages-liporedukt-js": () => import("./../../../src/pages/liporedukt.js" /* webpackChunkName: "component---src-pages-liporedukt-js" */),
  "component---src-pages-lutein-complex-js": () => import("./../../../src/pages/lutein-complex.js" /* webpackChunkName: "component---src-pages-lutein-complex-js" */),
  "component---src-pages-menopauzal-js": () => import("./../../../src/pages/menopauzal.js" /* webpackChunkName: "component---src-pages-menopauzal-js" */),
  "component---src-pages-miomal-js": () => import("./../../../src/pages/miomal.js" /* webpackChunkName: "component---src-pages-miomal-js" */),
  "component---src-pages-nevroherb-js": () => import("./../../../src/pages/nevroherb.js" /* webpackChunkName: "component---src-pages-nevroherb-js" */),
  "component---src-pages-pielonefral-js": () => import("./../../../src/pages/pielonefral.js" /* webpackChunkName: "component---src-pages-pielonefral-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-prostatizal-js": () => import("./../../../src/pages/prostatizal.js" /* webpackChunkName: "component---src-pages-prostatizal-js" */),
  "component---src-pages-prostatizal-plus-js": () => import("./../../../src/pages/prostatizal-plus.js" /* webpackChunkName: "component---src-pages-prostatizal-plus-js" */),
  "component---src-pages-psoriazal-js": () => import("./../../../src/pages/psoriazal.js" /* webpackChunkName: "component---src-pages-psoriazal-js" */),
  "component---src-pages-psoriazal-plus-js": () => import("./../../../src/pages/psoriazal-plus.js" /* webpackChunkName: "component---src-pages-psoriazal-plus-js" */),
  "component---src-pages-site-map-js": () => import("./../../../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-veniprotekt-js": () => import("./../../../src/pages/veniprotekt.js" /* webpackChunkName: "component---src-pages-veniprotekt-js" */)
}

