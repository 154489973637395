module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"","cookieName":"vh-ga-gtm-tracking","anonymize":true},"googleTagManager":{"trackingId":"GTM-5P94R25","cookieName":"vh-ga-gtm-tracking","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"226688036201461","cookieName":"vh-ga-gtm-tracking"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Вита Херб","short_name":"Вита Херб","start_url":"/","background_color":"#20232a","theme_color":"#20232a","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9afd41e1c5eecc9cee60630d2564b1a7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
